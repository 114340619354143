<template>
  <div>
    <!-- <div align="right">
      <div class="d-flex justify-content-end align-items-center">
        <span class="mx-50 small font-weight-bolder">Simplified</span>
        <b-form-checkbox switch v-model="agent.register_2.value" value="true" unchecked-value="false" />
      </div>
    </div> -->

    <!-- RAW SQL STRING ===================================================== -->
      <b-collapse :visible="true">
        <custom-input
          class="my-50 w-100"
          :possibleValues="possibleValues"
          v-model="agent.register_1"
        >
          <!-- <template #label>
            <span class="font-weight-bold">
              {{prependWord}} <span class="text-secondary"> . . . </span> <span >WHERE</span> <span class="text-secondary">. . .</span>
            </span>
          </template> -->
        </custom-input>

        <div class="mt-1 font-weight-bolder d-flex align-items-end">
          <h5 class="mr-50 text-secondary small">SQL Comparison Operators:</h5>
          <div class="copy-operator-btn rounded mx-25" v-for="op in comparisonOperators" :key="'operator-' + op">
            <h5 class="my-25 mx-75 d-block"> {{op}} </h5>
          </div>
          <span class="mx-50">. . .</span>
        </div>
      </b-collapse>
    <!-- ==================================================================== -->

    <!-- SIMPLIFIED COMPARISON ============================================== -->
    <!-- <b-collapse :visible="agent.register_2.value == 'true'">
      <div>
          
          <b-row no-gutters cols="12">

            <b-col cols="5">
              <custom-input
                class="my-50 w-100"
                :possibleValues="possibleValues"
                v-model="agent.register_1.value"
                noChanger
                :advanced="false"
              />
            </b-col>

            <b-col cols="2" class="d-flex justify-content-center align-items-center">
              <div align="center">
                <h2 class="m-0">{{comparator}}</h2>
                <div>
                  <b-button class="p-0 m-0 small" variant="none">
                    <span class="small">
                      change <b-icon icon="arrow-left-right" scale="0.7" />
                    </span>
                  </b-button>
                </div>                
            </div>
            </b-col>

            <b-col cols="5">
              <custom-input
                class="my-50 w-100"
                :possibleValues="possibleValues"
                v-model="agent.register_1.value"
                noChanger
                :advanced="false"
              />
            </b-col>

          </b-row>

          <div class="d-flex justify-content-center align-items-center my-1">
            <hr class="w-50 mx-1">
            <b-button class="mx-2 py-50 w-25" variant="outline-secondary">OR</b-button>
            <hr class="w-05">
            <b-button class="mx-2 py-50 w-25" variant="outline-secondary">AND</b-button>
            <hr class="w-50 mx-1">
          </div>

          <custom-input
            class="my-50 w-100"
            :possibleValues="possibleValues"
            v-model="agent.register_1.value"
            noChanger
            :advanced="false"
          />
        </div>

        <div>
          <h5 class="font-weight-bolder">SELECT <span class="text-secondary">Cols</span> FROM <span class="text-secondary">Table</span> WHERE...</h5>
        </div>
      <b-card class="bg-dark-blue mt-1">

        <div class="">d
          <inner-where-sql
            v-model="placeholderVar"
            :possibleValues="possibleValues"
          >
          </inner-where-sql>
        </div>
      </b-card>
    </b-collapse> -->
    <!-- ==================================================================== -->

  </div>
</template>

<script>
import customInput from '@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue'
import SwitchRegisterInput from '@/views/pages/middleware/MiddlewareManagement/Components/SwitchRegisterInput.vue'
import innerWhereSql from '@/views/pages/middleware/MiddlewareManagement/Components/Comparison/innerWhereSQL.vue'

import { 
  BCol,
  BRow,
  BFormInput, 
  BFormGroup, 
  BForm, 
  BBadge, 
  BModal,
  BTooltip, 
  BDropdown, 
  BButton,
  BFormCheckbox,
  BCollapse,
  BFormSelect,
  BCard
} from 'bootstrap-vue'


export default {
  components: {
    BCol,
    BRow,
    customInput, 
    BFormInput, 
    BFormGroup, 
    BForm, 
    BBadge, 
    BModal,
    BTooltip, 
    BDropdown, 
    BButton,
    BFormCheckbox,
    SwitchRegisterInput,
    BCollapse,
    BFormSelect,
    innerWhereSql,
    BCard
  },
  data() {
    return {
      comparisonOperators:[
        '=',
        '>',
        '<',
        '>=',
        "<=",
        "<>",
        'LIKE',
        'IN',
        'BETWEEN',
      ],
      comparator: '=',
      placeholderVar: {}
    }
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    possibleValues:{
      type: Object,
      required: true,
    },
    prependWord:{
      type: String,
      default: 'SELECT'
    }
  },
  computed: {
    agent: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.w-05{
  width: 5%;
}
.copy-operator-btn{
  background-color: rgba(22, 29, 49, 0.5);
  user-select: all;
}
</style>