<template>
  <div class="d-flex align-items-center">
    <h2 class="text-favorite font-weight-bolder mr-25 mb-1">(</h2>

    <custom-input
      :possibleValues="possibleValues"
      v-model="banana"
      noChanger
      :advanced="false"
      class="mx-50"
    />

    <h2 class="mb-1 mx-50">
      ≥
    </h2>

    <custom-input
      :possibleValues="possibleValues"
      v-model="banana"
      noChanger
      :advanced="false"
      class="mx-50"
    />

    <h2 class="text-favorite font-weight-bolder ml-25 mb-1">
      <span class="add-comparison-btn px-25"><b-icon icon="plus-circle" shift-v="-1" scale="0.75"></b-icon></span>
      )
      <span class="add-comparison-btn px-25"><b-icon icon="plus-circle" shift-v="-1" scale="0.75"></b-icon></span>
    </h2>
  </div>
</template>

<script>
import customInput from '@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue'
import SwitchRegisterInput from '@/views/pages/middleware/MiddlewareManagement/Components/SwitchRegisterInput.vue'

import { 
  BCol,
  BRow,
  BFormInput, 
  BFormGroup, 
  BForm, 
  BBadge, 
  BModal,
  BTooltip, 
  BDropdown, 
  BButton,
  BFormCheckbox,
  BCollapse,
  BFormSelect
} from 'bootstrap-vue'


export default {
  components: {
    BCol,
    BRow,
    customInput, 
    BFormInput, 
    BFormGroup, 
    BForm, 
    BBadge, 
    BModal,
    BTooltip, 
    BDropdown, 
    BButton,
    BFormCheckbox,
    SwitchRegisterInput,
    BCollapse,
    BFormSelect
  },
  data() {
    return {
      comparisonOperators:[
        '=',
        '>',
        '<',
        '>=',
        "<=",
        "<>"
      ],
      comparator: '=',
      banana: ''
    }
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    possibleValues:{
      type: Object,
      required: true,
    }
  },
  computed: {
    agent: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.w-05{
  width: 5%;
}

.add-comparison-btn{
  cursor: pointer;
  color: var(--secondary);
  &:hover{
    color: var(--light)
  }
}

</style>